const messages = {
  en: {
    brand: 'One',
    title: 'Smart Parking System',
    email: 'Email',
    pass: 'Password',
    oldpass: 'Old password',
    newpass: 'New password',
    newpass2: 'New password again',
    login: 'Login',
    verify: 'Verify',
    change: 'Change',
    consent: 'This website must store some data in your browser for it to function properly. If this is prevented by browser settings, the website becomes dysfunctional. Personal data suitable for identifying a natural person is not stored. By clicking the "I agree" button you consent to this.',
    agree: 'I agree',
    mfa: {
      back: 'Back to Login',
      info: 'Enter the verification code we sent you to your address by:',
      code: 'Code',
      methods: {
        email: 'Email',
        sms: 'Text message'
      }
    },
    errors: {
      wrong: 'Wrong email or password',
      wrong_code: 'Wrong verification code',
      auth_error: 'Server cannot authenticate the user, log out of admin area before using the Dashboard',
      server: 'Server error',
      response: 'Invalid response format',
      other: 'Error during connecting',
      consent: 'Please allow us to store data in your browser!',
      throttled: 'Too many login attempts'
    }
  },

  hu: {
    brand: 'One',
    title: 'Okos Parkolási Rendszer',
    email: 'E-mail',
    pass: 'Jelszó',
    oldpass: 'Régi jelszó',
    newpass: 'Új jelszó',
    newpass2: 'Új jelszó újra',
    login: 'Bejelentkezés',
    verify: 'Ellenőrzés',
    change: 'Megváltoztat',
    consent: 'A weboldal működéséhez elengedhetetlen, hogy a böngészőjében a weboldal megfelelő működéséhez szükséges adatok letárolásra kerüljenek. Amennyiben a böngésző adatvédelmi preferenciáinak beállításával ezen adatok tárolása letiltásra kerül, a weboldal használhatatlanná, működésképtelenné válik. A weboldal használata során természetes személy beazonosítására alkalmas személyes adat tárolására nem kerül sor. Az „Elfogadom” gomb megnyomásával hozzájárul az adattároláshoz.',
    agree: 'Elfogadom',
    mfa: {
      back: 'Vissza a bejelentkezéshez',
      info: 'Írja be az Önnek küldött ellenőrző kódot. Küldés módja:',
      code: 'Ellenőrző kód',
      methods: {
        email: 'Email',
        sms: 'SMS'
      }
    },
    errors: {
      wrong: 'Hibás e-mail vagy jelszó',
      wrong_code: 'Hibás ellenőrző kód',
      auth_error: 'Szerver hiba - Belépés előtt lépjen ki az adminisztrátori felületről',
      server: 'Szerver hiba',
      response: 'Érvénytelen szerver válasz',
      other: 'Hiba csatlakozás közben',
      consent: 'Kérjük járuljon hozzá az ön böngészőjében való adattároláshoz!',
      throttled: 'Túl sok bejelentkezési próbálkozás'
    },
  }
};

export default messages;


/*
################################################################################
#                                                                              #
# COPYRIGHT Ericsson 2018                                                      #
#                                                                              #
# The copyright to the computer program(s) herein is the property of Ericsson  #
# AB. The programs may be used and/or copied only with written permission      #
# from Ericsson AB. or in accordance with the terms and conditions stipulated  #
# in the agreement/contract under which the program(s) have been supplied.     #
#                                                                              #
################################################################################
*/
