import Vue from 'vue';

import VueRouter from 'vue-router';
Vue.use(VueRouter);

import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
import translations from './translations.js';
const i18n = new VueI18n({
  locale: window.localStorage.getItem('lang') || 'hu',
  messages: translations,
});

import App from './app.vue';
import Login from './login';
import Chpw from './chpw';

const router = new VueRouter({
  routes: [
    {name: 'login', path: '/login', component: Login},
    {name: 'verify', path: '/verify/:verify_method', component: Login, props: true },
    {name: 'chpw', path: '/chpw', component: Chpw},
    {path: '*', redirect: '/login'}
  ]
});

var app = new Vue({
  el: '#app',
  router,
  i18n,
  render: h => h(App)
});


/*
################################################################################
#                                                                              #
# COPYRIGHT Ericsson 2018                                                      #
#                                                                              #
# The copyright to the computer program(s) herein is the property of Ericsson  #
# AB. The programs may be used and/or copied only with written permission      #
# from Ericsson AB. or in accordance with the terms and conditions stipulated  #
# in the agreement/contract under which the program(s) have been supplied.     #
#                                                                              #
################################################################################
*/
