<template>
  <div>
    <div id="bg"></div>
    <router-view id="container"></router-view>
    <transition name="slide">
      <consent v-show="!consent_accepted" v-model="consent_accepted"></consent>
    </transition>
  </div>
</template>
<style lang="less">
@import './global.less';

// Mont Regular
@font-face {
  font-family: 'Mont';
  src:  url('../res/font/Mont-Regular.woff') format('woff'),
        url('../res/font/Mont-Regular.ttf') format('truetype');
}
// Mont Bold
@font-face {
  font-family: 'Mont';
  font-weight: 700;
  src:  url('../res/font/Mont-Bold.woff') format('woff'),
        url('../res/font/Mont-Bold.ttf') format('truetype');
}
// Mont Black
@font-face {
  font-family: 'Mont';
  font-weight: 900;
  src:  url('../res/font/Mont-Black.woff') format('woff'),
        url('../res/font/Mont-Black.ttf') format('truetype');
}

// Inter Regular
@font-face {
  font-family: 'Inter';
  font-weight: 400;
  src:  url('../res/font/Inter-Regular.otf') format('opentype');
}
// Inter Bold
@font-face {
  font-family: 'Inter';
  font-weight: 700;
  src:  url('../res/font/Inter-Bold.otf') format('opentype');
}
// Inter Medium
@font-face {
  font-family: 'Inter';
  font-weight: 500;
  src:  url('../res/font/Inter-Medium.otf') format('opentype');
}









#title {
  font-family: Mont, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}

#bg{
  background-color: @white;
  background-image: url('../res/bg.jpg');
  background-position: top center;
  // Max 120% or cover
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1;
}

#container {
  // position: absolute;
  //height: 100%;
  //width: 100%;

  display: flex;
  justify-content: center; /* Horizontally centers the item */
  align-items: flex-start; /* Aligns items to the top */
}

#popover {
  padding: 0 @blockSpacing;
  background-color: @accent;
  border-radius: 0 0 24px 24px;
}

#logo {
  background-image: url('../res/onesol-logo-primary-darkbg.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 128px;
  margin: @blockSpacing auto;
}

.group{
  position:relative;
  margin-bottom: @blockSpacing;

  input{
    color: darken(white, 87%);

    width: 300px;
    height: 24px;
    font-size: 16px;
    padding: 8px 12px;

    border: none;
    outline: 1px solid darken(white, 60%);
    border-radius: 8px;

    box-shadow: none;

    &:hover {
      outline-color: darken(white, 87%);
    }

    &:focus{
      // outline: none;
      //border-bottom: none;
      outline-color: @accent;
      outline-width: 2px;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30),
      0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    }
  }

  label{
    color: @secondaryText;
    font-size: 16px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 12px;
    top: 8px;
    -webkit-transition: 0.2s ease all;
       -moz-transition: 0.2s ease all;
            transition: 0.2s ease all;
  }
  input:focus, input:valid{
      ~ label{
      padding: 0 4px;
      top: -13px;
      font-size: 13px;
      background: white;
    }
  }
  input:focus {
    ~ label {
      color: @accent;
    }
  }

}

  /* BOTTOM BARS ================================= */
  /*  .bar{
    position: relative;
    display: block;
    width: 300px;

    &:before, &:after{
      content: '';
      height: 2px;
      width: 0;
      bottom: 1px;
      position: absolute;
      background: @accent;
      -webkit-transition: 0.2s ease all;
         -moz-transition: 0.2s ease all;
              transition: 0.2s ease all;
    }
    &:before{
      left: 50%;
    }
    &:after{
      right: 50%;
    }
  }*/

  input:focus{
    ~ .bar:before, ~ .bar:after{
      width: 50%;
    }
  }



// Import .btn-lg as base for .btn
.btn {
  display: block;
  margin: 0 auto;
}

.slide-enter-active, .slide-leave-active {
  transition: all .2s ease;
}
.slide-enter, .slide-leave-to{
  margin-bottom: -50px;
}

</style>
<script>
import Consent from './consent';

function saveToLS(){
  if(this.consent_accepted)
    window.localStorage.setItem('lang', this.$i18n.locale);
}

export default {
  name: 'app',
  components: {Consent},
  data(){
    return{
      consent_accepted: true
    };
  },
  watch: {
    '$i18n.locale': saveToLS,
    consent_accepted: saveToLS
  }
};
</script>


<!--
################################################################################
#                                                                              #
# COPYRIGHT Ericsson 2018                                                      #
#                                                                              #
# The copyright to the computer program(s) herein is the property of Ericsson  #
# AB. The programs may be used and/or copied only with written permission      #
# from Ericsson AB. or in accordance with the terms and conditions stipulated  #
# in the agreement/contract under which the program(s) have been supplied.     #
#                                                                              #
################################################################################
-->
