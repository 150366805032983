<template>
<div>
  <div id="popover">
    <div id="logo"></div>
    <div id="title">{{$t('title')}}</div>
    <div id="box">
      <form @submit.prevent="submit">
        <div class="group">
          <input type="password" v-model="oldpass" required>
          <span class="bar"></span>
          <label>{{$t('oldpass')}}</label>
        </div>
        <div class="group">
          <input type="password" v-model="newpass" required>
          <span class="bar"></span>
          <label>{{$t('newpass')}}</label>
        </div>
        <div class="group">
          <input type="password" v-model="newpass2" required>
          <span class="bar"></span>
          <label>{{$t('newpass2')}}</label>
        </div>
        <button :class="['btn', match ? '' : 'disabled']" type="submit">{{$t('change')}}</button>
      </form>
      <div id="errors" v-if="errors">
        <transition name="fade">
          <div v-if="errors">
            <div v-for="e in errors">{{e}}</div>
          </div>
        </transition>
      </div>
      <langsl></langsl>
    </div>
  </div>
</div>
</template>

<style lang="less" scoped>
@import './global.less';

#title{
  width: 400px;
  margin: @blockSpacing auto;
  font-size: 40px;
  text-align: center;
  color: #fff;
  line-height: 45px;
}

#box{
  width: 400px;
  margin: @blockSpacing auto;
  background-color: #fff;
  padding: @blockSpacing @blockSpacing calc(@blockSpacing / 1.5) @blockSpacing;
  box-sizing: border-box;
  border-radius: @brad;

  #errors{
    font-size: 14px;
    text-align: center;
    margin-top: 30px;
    height: 30px;
    color: red;

    .fade-enter-active,
    .fade-leave-active,
    .fade-move {
      transition: 500ms cubic-bezier(0.59, 0.12, 0.34, 0.95);
      transition-property: opacity;
    }

    .fade-enter, .fade-leave-to{
      opacity: 0;
    }
  }
}
</style>

<script>
import api from './api.js';

import Langsl from './langsl.vue';

export default {
  name: 'login',
  components: {Langsl},
  data(){
    return {
      oldpass: '',
      newpass: '',
      newpass2: '',
      match: true,
      errors: []
    };
  },
  mounted(){
    try{
      api.load()
    }catch(e){
      this.logout();
    };
  },
  watch: {
    newpass2(v){
      if(v != this.newpass)
        this.match = false;
      else
        this.match = true;
    }
  },
  methods: {
    logout(){
      window.localStorage.removeItem('jwt');
      this.$router.push('login');
    },
    submit(){
      if(this.match){
        api.chpw(this.oldpass, this.newpass, this.$i18n.locale)
        .then((res)=>{
          this.errors = [res.data.detail];
          this.oldpass = '';
          this.newpass = '';
          this.newpass2 = '';
          setTimeout(()=>{
            this.errors = null;
          }, 5000);
        })
        .catch((e)=>{
          if(e == null){
            this.logout();
            return;
          }
          console.log(e.response.data);
          var e = e.response.data;
          if('old_password' in e)
            this.errors = e['old_password'];
          if('new_password' in e)
            this.errors = e['new_password'];
          setTimeout(()=>{
            this.errors = null;
          }, 5000);
        });
      }
    }
  }
};
</script>


<!--
################################################################################
#                                                                              #
# COPYRIGHT Ericsson 2018                                                      #
#                                                                              #
# The copyright to the computer program(s) herein is the property of Ericsson  #
# AB. The programs may be used and/or copied only with written permission      #
# from Ericsson AB. or in accordance with the terms and conditions stipulated  #
# in the agreement/contract under which the program(s) have been supplied.     #
#                                                                              #
################################################################################
-->
