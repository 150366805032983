<template>
  <div id="langsl" class="tab-center">
    <a v-for="lang in langs" :key="lang" :class="{selected: selected === lang, tab: true}" @click="select(lang)" href="#"><span>{{lang.toUpperCase()}}</span></a>
  </div>
</template>

<style lang="less">
@import './global.less';

#langsl{
  color: @secondaryText;
  width: 100%;
  margin-top: @blockSpacing / 1.5;
  text-align: center;
}
</style>

<script>
export default {
  name: 'langsl',
  data(){
    return {
      langs: ['en', 'hu'],
      selected: this.$i18n.locale
    };
  },
  methods: {
    select(lang){
      this.selected = lang;
      this.$i18n.locale = lang;
    }
  }
};
</script>


<!--
################################################################################
#                                                                              #
# COPYRIGHT Ericsson 2018                                                      #
#                                                                              #
# The copyright to the computer program(s) herein is the property of Ericsson  #
# AB. The programs may be used and/or copied only with written permission      #
# from Ericsson AB. or in accordance with the terms and conditions stipulated  #
# in the agreement/contract under which the program(s) have been supplied.     #
#                                                                              #
################################################################################
-->
