import {urls, debug} from '../config.yml';

import axios from 'axios';

var access = '';
var refresh = '';
var ephemeral_token = '';
var exp = null;

function sleep(ms){
  return new Promise(resolve => setTimeout(resolve, ms));
}

async function redirect(to){
  if(!debug || confirm('Redirect to ' + to)){
    window.location.replace(to);
    await sleep(5000);
  }
}

function parseExp(token){
  return JSON.parse(atob(token.split('.')[1])).exp;
}

function load(){
  var jwt = window.localStorage.getItem('jwt');
  if(jwt == null)
    throw null;
  jwt = JSON.parse(jwt);
  if(!('access' in jwt) || !('refresh' in jwt))
    throw null;
  access = jwt.access;
  refresh = jwt.refresh;
  exp = parseExp(access);
}

var refresher = null;

async function refreshtoken(){
  console.log('Refreshing token');
  try{
    var res = await axios.post(urls.backend + '/api/token/refresh/', {refresh});
    access = res.data.access;
    exp = parseExp(access);
    window.localStorage.setItem('jwt', JSON.stringify({access, refresh}));
  }catch(e){
    throw null;
  }finally{
    refresher = null;
  }
}

async function getToken(forceUpdate = false){
  if(exp * 1000 < (new Date()).valueOf() || forceUpdate){
    refresher = (refresher || refreshtoken());
    await refresher;
    return access;
  }else{
    return access;
  }
}

async function logout(){
  window.localStorage.removeItem('jwt');
  await redirect(urls.auth);
}

async function chpw(old_password, new_password, errlang){
  var res = await api.put('/me/changepassword/', {old_password, new_password}, {headers: {'Accept-Language': errlang}})
  setTimeout(()=>{
    redirect(urls.frontend);
  }, 2000);
  return res;
}

const api = axios.create({
  baseURL: urls.backend + '/api'
});

api.load = load;
api.logout = logout;
api.chpw = chpw;

api.interceptors.request.use(
  async function(config){
    config.headers['Authorization'] = 'Bearer ' + await getToken();
    return config;
  },
  async function(error){
    return error;
  }
);
api.interceptors.response.use(
  res=>res,
  async function(error){
    const {config, response: {status}} = error;
    if(status === 403){
      config.headers['Authorization'] = 'Bearer ' + await getToken(true);
      return axios(config);
    }
    throw error;
  }
);

// --------

async function check(){
  var jwt = window.localStorage.getItem('jwt');
  if(jwt !== null){
    redirect(urls.frontend);
  }
}

async function loginOrMfa(email, password){
  console.log('Logging in');
  try{
    var res = await axios.post(urls.backend + '/api/token/', {email, password});
    // Normal login
    if (res.data.access && res.data.refresh) {
      console.log('Login confirmed');
      window.localStorage.setItem('jwt', JSON.stringify(res.data));
      redirect(urls.frontend);
    // MFA verification request
    } else if (res.data.ephemeral_token) {
      console.log('MFA verification requested');
      window.localStorage.removeItem('jwt');
      ephemeral_token = res.data.ephemeral_token;
      access = '';
      refresh = '';
      throw {verify:true, method: res.data.method, response: res}
    } else {
      throw 'response';
    }
  }catch(e){
    if (!e.response || e.response.status != 200) {
      console.log('Login failed', e.response && e.response.data ? e.response.data : e);
    }
    if(!e.response)
      throw 'other';
    if(e.response.status == 400 || e.response.status == 401)
      throw 'wrong';
    if(e.response.status == 403)
      throw 'auth_error';
    if(e.response.status == 429)
      throw 'throttled';
    throw e;
  }
}

async function verify(code){
  if (ephemeral_token === '') {
    throw 'wrong';
  }
  console.log('Verifying MFA code');
  try{
    var res = await axios.post(urls.backend + '/api/token/code/', {ephemeral_token, code});
    if (res.data.access && res.data.refresh) {
      console.log('Login confirmed by verification');
      window.localStorage.setItem('jwt', JSON.stringify(res.data));
      redirect(urls.frontend);
    } else {
      throw 'response';
    }
  }catch(e){
    if (!e.response || e.response.status != 200) {
      console.log('MFA verification failed', e.response && e.response.data ? e.response.data : e);
    }
    if(!e.response)
      throw 'other';
    if(e.response.status == 400 || e.response.status == 401)
      throw 'wrong_code';
      if(e.response.status == 403)
      throw 'auth_error';
    if(e.response.status == 429)
      throw 'throttled';
    throw e;
  }
}

api.check = check;
api.login = loginOrMfa;
api.verify = verify;

export default api;


/*
################################################################################
#                                                                              #
# COPYRIGHT Ericsson 2018                                                      #
#                                                                              #
# The copyright to the computer program(s) herein is the property of Ericsson  #
# AB. The programs may be used and/or copied only with written permission      #
# from Ericsson AB. or in accordance with the terms and conditions stipulated  #
# in the agreement/contract under which the program(s) have been supplied.     #
#                                                                              #
################################################################################
*/
