<template>
  <div id="consent-box">
    <div>{{$t('consent')}}</div>
    <button class="btn btn-md" type="button" @click="agree">{{$t('agree')}}</button>
  </div>
</template>

<style lang="less">
@import './global.less';

#consent-box{
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: @white;
  color: @accentPass;
  padding: 10px 40px 8px 10px;
  font-size: 16px;
  box-sizing: border-box;

  div{
    float: left;
    margin: 8px 0;
  }

  .btn{
    float: right;
  }
}
</style>

<script>
export default {
  name: 'consent',
  props: ['value'],
  mounted(){
    var c = window.localStorage.getItem('cst') || false;
    setTimeout(()=>{
      this.$emit('input', c);
    }, 500);
  },
  methods: {
    agree(){
      this.$emit('input', true);
      window.localStorage.setItem('cst', true);
    }
  }
};
</script>


<!--
################################################################################
#                                                                              #
# COPYRIGHT Ericsson 2018                                                      #
#                                                                              #
# The copyright to the computer program(s) herein is the property of Ericsson  #
# AB. The programs may be used and/or copied only with written permission      #
# from Ericsson AB. or in accordance with the terms and conditions stipulated  #
# in the agreement/contract under which the program(s) have been supplied.     #
#                                                                              #
################################################################################
-->
