<template>
<div>
  <div id="popover">
    <div id="logo"></div>
    <div id="title">{{$t('title')}}</div>
    <div id="box">
      <form @submit.prevent="submitLogin" id="login" v-if="this.$route.name === 'login'">
        <div class="group">
          <input ref="login" type="text" autocomplete="off" v-model="email" required>
          <span class="bar"></span>
          <label>{{$t('email')}}</label>
        </div>
        <div class="group">
          <input type="password" autocomplete="off" v-model="password" required>
          <span class="bar"></span>
          <label>{{$t('pass')}}</label>
        </div>
        <button :class="['btn btn-lg', $parent.consent_accepted ? '' : 'disabled']" type="submit">{{$t('login')}}</button>
      </form>

      <form @submit.prevent="submitVerify" id="verify" v-if="this.$route.name === 'verify'">
        <div class="group">
          <input ref="verify" type="text" autocomplete="off" v-model="code" required>
          <span class="bar"></span>
          <label>{{$t('mfa.code')}}</label>
          <div class="info">{{$t('mfa.info')}} {{$t('mfa.methods.' + verify_method)}}</div>
        </div>
        <button :class="['btn btn-md', $parent.consent_accepted ? '' : 'disabled']" type="submit">{{$t('verify')}}</button>
      </form>

      <transition-group name="fade" tag="div" id="errors" v-if="Object.values(errors).some(e=>e)">
        <div v-for="(v, k) in errors" :key="k" v-if="v">{{$t('errors.'+k)}}</div>
      </transition-group>

      <div v-if="this.$route.name === 'verify'">
          <router-link class="back" :to="{name: 'login'}">⬅ {{$t('mfa.back')}}</router-link>
      </div>

      <langsl></langsl>
    </div>
  </div>
</div>
</template>

<style lang="less" scoped>
@import './global.less';

#title{
  width: 400px;
  margin: @blockSpacing auto;
  font-size: 40px;
  text-align: center;
  color: #fff;
  line-height: 50px;
}

#box{
  width: 400px;
  margin: @blockSpacing auto;

  background-color: #fff;
  padding: @blockSpacing @blockSpacing calc(@blockSpacing / 1.5) @blockSpacing;
  box-sizing: border-box;
  border-radius: @brad;

  .back {
    display: block;
    margin-top: @blockSpacing;
    color: #999;
    text-decoration: none;
  }

  .info {
    margin-top: @blockSpacing / 2;
    font-size: 14px;
    // color: red;
  }

  #errors{
    font-size: 14px;
    text-align: center;
    margin-top: 30px;
    height: 30px;
    color: red;

    .fade-enter-active,
    .fade-leave-active,
    .fade-move {
      transition: 500ms cubic-bezier(0.59, 0.12, 0.34, 0.95);
      transition-property: opacity;
    }

    .fade-enter, .fade-leave-to{
      opacity: 0;
    }
  }
}
</style>

<script>
import api from './api.js';

import Langsl from './langsl.vue';

export default {
  name: 'login',
  components: {Langsl},
  props: ['verify_method'],
  data(){
    return {
      email: '',
      password: '',

      code: '',

      errors: {
        consent: false,
        wrong: false,
        wrong_code: false,
        auth_error: false,
        server: false,
        response: false,
        other: false,
        throttled: false
      }
    };
  },
  mounted(){
    api.check();
  },
  beforeRouteEnter(to, from, next) {
    if      (to.name == 'login' ) next(c=>c.focusLogin());
    else if (to.name == 'verify') next(c=>c.focusVerify());
    else next();
  },
  methods: {
    showerror(e){
      if(typeof e == 'string') {
        this.errors[e] = true;
        setTimeout(()=>{
          this.errors[e] = false;
        }, 15000);
      } else {
        alert('Error: ' + e)
      }
    },
    focusVerify(){
      this.$refs.verify.focus();
    },
    submitVerify(){
      if(!this.$parent.consent_accepted){
        this.showerror('consent');
        return;
      }
      api.verify(this.code)
        .catch((e)=>{
          this.showerror(e);
        });
    },
    focusLogin(){
      this.$refs.login.focus();
    },
    submitLogin(){
      if(!this.$parent.consent_accepted){
        this.showerror('consent');
        return;
      }
      api.login(this.email, this.password)
        .catch((e)=>{
          // Switch to MFA verification mode              
          if (e.verify) {
            this.code = '';
            this.$router.push({name: 'verify', params: { verify_method: e.method }});
            return;
          }

          this.showerror(e);
        });
    }
  }
};
</script>


<!--
################################################################################
#                                                                              #
# COPYRIGHT Ericsson 2018                                                      #
#                                                                              #
# The copyright to the computer program(s) herein is the property of Ericsson  #
# AB. The programs may be used and/or copied only with written permission      #
# from Ericsson AB. or in accordance with the terms and conditions stipulated  #
# in the agreement/contract under which the program(s) have been supplied.     #
#                                                                              #
################################################################################
-->
